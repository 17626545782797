<template>
  <v-snackbar
    :value="snackbar.length > 0"
    @input="hideSnackbar"
    :timeout="5000"
    min-width="100px"
    :color="snackbarColor"
    top
    right
  >
    <span style="font-weight: 500;">
      {{ snackbarTitle }}
    </span>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    },
    snackbarTitle() {
      return this.$store.state.snackbarTitle;
    },
    snackbarColor () {
      return this.$store.state.snackbarColor;
    }
  },
  methods: {
    hideSnackbar() {
      this.$store.commit('HIDE_SNACKBAR');
    },
  },
}
</script>

<style lang="scss" scoped>
</style>